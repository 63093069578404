import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

// Helper Functions
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const HrAndPayrollServices = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/merchant-services/payroll-services/hero-payroll-services-021523-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-services",
      title: "Business Services"
    },
    {
      id: 3,
      active: true,
      title: "HR and Payroll Services"
    }
  ];

  const heroChevronData = {
    id: "business-services-hr-and-payroll-services-hero",
    ...getHeroImgVariables(imgData),
    altText: "Smiling businesswoman working on laptop.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "All-In-One Payroll and HR Built for Small Business"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Get Started Today",
            url: "https://go.heartland.us/wafd#form",
            class: "btn-white",
            target: "blank"
          }
        }
      ]
    }
  };

  const seoTitle = "Payroll Services for Small Business";
  const seoDesc =
    "Learn about WaFd Bank's small business payroll services with Heartland. Services include payroll processing, HR solutions, labor management and more.";
  const SEOData = {
    title: seoTitle,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: seoTitle
      },
      {
        name: "description",
        content: seoDesc
      },
      {
        property: "og:title",
        content: seoTitle
      },
      {
        property: "og:description",
        content: seoDesc
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/hr-and-payroll-services"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-payroll-services-021523.jpg"
      }
    ]
  };

  const payrollListData = {
    accountName: "payroll-list",
    items: [
      {
        id: 1,
        text: "Multi-payment options"
      },
      {
        id: 2,
        text: "Employee access"
      },
      {
        id: 3,
        text: "Management reporting"
      },
      {
        id: 4,
        text: "Payroll tax compliance, payment and filing"
      },
      {
        id: 5,
        text: "W2 and 1099 reporting"
      },
      {
        id: 6,
        text: "401(k)"
      },
      {
        id: 6,
        text: "Worker's comp and general ledger reporting"
      },
      {
        id: 6,
        text: "Three-year price lock"
      }
    ]
  };

  const heartlandTimeListData = {
    accountName: "heartland-time-list",
    items: [
      {
        id: 1,
        text: "Track projects and people 24/7"
      },
      {
        id: 2,
        text: "Time tracking"
      },
      {
        id: 3,
        text: "Employee scheduling&mdash;Swap and cover shifts"
      },
      {
        id: 4,
        text: "Simplify time off processes"
      },
      {
        id: 5,
        text: "Build smarter cloud-based schedules"
      }
    ]
  };

  const humanResourcesListData = {
    accountName: "human-resources-time-list",
    items: [
      {
        id: 1,
        text: "A library of customizable templates and HR tools"
      },
      {
        id: 2,
        text: "Automatic monitoring and real-time alerts about legal changes"
      },
      {
        id: 3,
        text: "Expert advice&mdash;and even dedicated support&mdash;for core HR work and the most challenging situations"
      },
      {
        id: 4,
        text: "Employee handbook"
      },
      {
        id: 5,
        text: "Online storage of employee files"
      },
      {
        id: 5,
        text: "HR reporting"
      }
    ]
  };

  const heartlandHireListData = {
    accountName: "heartland-hire-list",
    items: [
      {
        id: 1,
        text: "Post jobs on social media"
      },
      {
        id: 2,
        text: "Text to apply"
      },
      {
        id: 3,
        text: "Applicant tracking system"
      },
      {
        id: 4,
        text: "Integrated onboarding"
      },
      {
        id: 5,
        text: "Work opportunity tax credit (WOTC) screening and processing"
      },
      {
        id: 5,
        text: "Candidate screening&mdash;background checks"
      }
    ]
  };

  const HeartlandGetStartedButton = ({ id }) => (
    <a
      href="https://go.heartland.us/wafd#form"
      id={id}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-link no-min-width w-100 w-sm-auto"
    >
      Get Started
    </a>
  );

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container" id="payroll-services-heading-section">
        <h1>HR and Payroll Services</h1>
        <h3>
          WaFd Bank has teamed up with Heartland<sup>&reg;</sup> to offer easy payroll and HR solutions to manage your
          employees and protect your business.
        </h3>
        <a
          href="https://go.heartland.us/wafd#form"
          id="heartland-get-started-cta"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary w-100 w-sm-auto"
        >
          Get Started
        </a>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="Manager looking at Heartland Payroll software on a laptop in an office."
                src="../../../images/thumbnail-heartland-payroll-050824.jpg"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-semi-bold">Heartland Payroll</h2>
              <p>
                Our service is designed to be user-friendly, with streamlined processing for a simpler experience.
                You'll receive dedicated support from a specialist who knows you by name, ensuring timely assistance.
                Enjoy 24/7 self-service payroll access, fair and transparent pricing, and hassle-free HR with access to
                professionals, email alerts, customizable templates, and more.
              </p>
              <List {...payrollListData} />
              <HeartlandGetStartedButton id="heartland-payroll-get-started-cta" />
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <h2 className="font-weight-semi-bold">Heartland Time</h2>
              <p>
                Ditch the spreadsheets for good! Our intuitive time management software simplifies online timesheet
                creation, clock-in setup, billable hour tracking, detailed report generation, and much more.
              </p>
              <List {...heartlandTimeListData} />
              <HeartlandGetStartedButton id="heartland-time-get-started-cta" />
            </div>
            <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="Heartland Time software on a laptop in a cafe."
                src="../../../images/thumbnail-heartland-time-050824.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="Heartland HR software on a laptop."
                src="../../../images/thumbnail-human-resources-050824.jpg"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-semi-bold">Human Resources</h2>
              <p>
                Transition from a reactive approach to proactive with our human resources software. Our cloud-based
                solution simplifies handling everything from compliance inquiries to employee management challenges,
                empowering you to stay ahead of your HR needs.
              </p>
              <List {...humanResourcesListData} />
              <HeartlandGetStartedButton id="heartland-human-resources-get-started-cta" />
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <h2 className="font-weight-semi-bold">Heartland Hire</h2>
              <p>
                Swap out spontaneous hiring processes for a comprehensive tech solution. Effortlessly pinpoint top
                talent, accelerate interviews, streamline onboarding, and potentially collect tax credits for eligible
                candidates (check with your tax advisor). Managing turnover has never been simpler, ensuring you attract
                the perfect talent effortlessly.
              </p>
              <List {...heartlandHireListData} />
              <HeartlandGetStartedButton id="heartland-hire-get-started-cta" />
            </div>
            <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="Three people in a job interview, shaking hands."
                src="../../../images/thumbnail-heartland-hire-050824.jpg"
              />
            </div>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default HrAndPayrollServices;
